<template>
  <div class="bg">
    <van-form @submit="onSubmit">
      <van-field readonly clickable required name="problemCategory" :value="form.problemCategory" label="问题分类" placeholder="点击选择问题分类"
        right-icon="question-o" @click="classify" :disabled="isDisable" :rules="[{ required: true}]"/>
      <van-field v-model="form.problemExplain" rows="2" name="problemExplain" autosize label="问题描述" type="textarea" maxlength="200" placeholder="请详细描述遇到的问题" show-word-limit />
      <van-field required v-model="form.customerName" label="联系人" name="customerName" placeholder="请输入联系人姓名" :rules="[{ required: true, message: '' }]" />
      <van-field required v-model="form.customerPhone" label="联系电话" name="customerPhone" type="tel" placeholder="请输入手机号" :error-message="errorPhone" @blur="loseFocus" :rules="[{ required: true, message: '' }]" />
      <van-field required readonly clickable v-model="buyDate" label="购买日期" name="purchaseDate" placeholder="请选择购买日期" right-icon="calendar-o" @click="showCalendar = true" :rules="[{ required: true}]"/>
      <van-field required v-model="form.brand" label="产品品牌" name="brand" placeholder="请选择产品品牌" :rules="[{ required: true}]"/>
      <van-field required v-model="form.productModel" label="产品型号" name="productModel" placeholder="请选择产品型号" :rules="[{ required: true}]"/>
      <van-field v-model="form.productNo" label="产品编号" name="productNo" placeholder="扫描机身条形码获取" right-icon="scan" @bind:click-icon="startScan"/>
      <div class="myTip"><van-icon name="warning-o" /> 请上传购买凭证，否则将视为过保</div>
      <van-field name="uploadImage" label="拍照上传">
        <template #input>
          <van-uploader v-model="fileList" accept="media" :after-read="afterRead" :before-delete="deleteImg"/>
        </template>
      </van-field>
      <van-field required v-model="form.receiveAddress" label="收货地址" name="receiveAddress" placeholder="请输入收货地址" :rules="[{ required: true}]"/>
      <van-field required name="isSameAddress" label="上门地址" :rules="[{ required: true, message: '请选择'}]">
        <template #input>
          <van-radio-group v-model="form.isSameAddress" @change="changeType" direction="horizontal">
            <van-radio name="true">与收货地址一致</van-radio>
            <van-radio name="false">不一致</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div v-if="form.isSameAddress==='false' || form.type==='INSTALL'">
        <van-field required v-model="form.serviceAddress" label="上门地址" name="serviceAddress" placeholder="请输入师傅上门地址" :rules="[{ required: true}]"/>
      </div>
      <div style="margin: 18px 65px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" @change="onChangePicker" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>
    <van-calendar v-model="showCalendar" :min-date="minDate" :max-date="maxDate" color="#1989fa" @confirm="onselectDate" />
  </div>
</template>

<script>
import { Toast } from 'vant';
// import { addSaleForm } from '@/api/after_sale'
export default {
  name: 'GoInstall',
  data() {
    return {
      classification: '',
      problems: {},
      columns: [],
      errorPhone: '',
      showPicker: false,
      showCalendar: false,
      isDisable: false,
      
      minDate: new Date(2010, 1, 1),
      maxDate: new Date(),
      buyDate: '',
      fileList: [],
      form: {
        type: '',
        problemCategory: '',
        problemExplain: '',
        customerName: '',
        customerPhone: '',
        brand: '',
        purchaseDate: '',
        productModel: '',
        productNo: '',
        uploadImage: [],
        receiveAddress: '',
        isSameAddress: true,
        serviceAddress: '',
        afterSaleType: "WX",
        monthlyStatement: null,
        remark: ""
      }
    }
  },
  mounted(){
    this.getGuides()
  },
  methods: {
    afterRead(file) {
      const forms = new FormData();
      forms.append("file", file.file);
      this.$http.post("/api/files/upload/image",forms,
      {
        headers: {"content-type": "multipart/form-data"},
      }
      ).then((res) => {
        console.log(res);
        const newData = res.data.data
        this.form.uploadImage.push(newData)
      })
    },
    deleteImg(e, data){
      this.form.uploadImage = this.form.uploadImage.filter((item, i) => {
        return i !== data.index
      })
      this.fileList = this.fileList.filter((item, i) => {
        return i !== data.index
      })
    },
    getGuides(){
      this.$http.get(`/api/after-sale/guideLevelGroup`,{
      }).then(res => {
        if(res.status===200){
          console.log(res.data);
          let guide = {};
          res.data.data.map(function(v) {
            if (!guide[v.name]) {
              guide[v.name] = [];
              v.guides.forEach(function(vv) {
                guide[v.name].push(vv.name);
              });
            }
          });
          this.problems = guide,
          this.columns = [
              { values: Object.keys(guide) },
              { values: guide['清洗功能异常'] }
            ]
        }
      })
    },
    startScan(){
      this.$router.push({name:'ScanCodePage', params: { form: this.form }})
    },
    loseFocus () {
      var RegCellPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (this.form.customerPhone.search(RegCellPhone)) {
        this.errorPhone = '手机号格式错误'
      } else {
        this.errorPhone = ''
      }
    },
    classify() {
      this.showPicker = true
    },
    changeType(e){
      if (e===true){
        this.form.isSameAddress = true
      } else if (e===false){
        this.form.isSameAddress = false
      }
    },
    onChangePicker(picker, value) {
      picker.setColumnValues(1, this.problems[value[0]]);
    },
    onConfirm(value) {
      this.form.problemCategory = value[0]+' / '+value[1];
      this.showPicker = false;
    },
    onselectDate(date) {
      this.form.purchaseDate = date
      this.buyDate = `${date.getYear()+1900}年${date.getMonth() + 1}月${date.getDate()}日`;
      this.showCalendar = false;
    },
    async onSubmit(values) {
      const payLoad = this.form
      console.log('submit', values, payLoad);
      this.$http.post('/api/after-sale/REPAIR', payLoad).then(res => {
        if(res.status===200){
          Toast.success('提交成功');
          window.close()
        }
        console.log('上传申请单', res);
      })
    },
  }
}
</script>

<style>
.bg {
  width: 375px;
  margin: 20px auto;
}
.myTip{
  font-size: 13px;
  color: #555;
  margin: 5px 15px;
  text-align: left;
}
</style>
